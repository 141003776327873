<template>
    <div :id="id" :class="classAttr">
        <rq-grid-action-header
            :title="title"
            :title-size="titleSize"
            :actions="actions"
            :theme="theme"
            :search-mode="searchMode"
            :show-include-inactive="showIncludeInactive"
            :target-inactive-column="targetInactiveColumn"
            :hide-search="hideSearch"
            :hide-toolbar="hideToolbar"
            :hide-default-actions="hideDefaultActions"
            :hide-settings="hideSettings"
            :hide-show-column-chooser="hideShowColumnChooser"
            :hide-export="hideExport"
            :hide-reset="hideReset"
            :hide-clear-filters="hideClearFilters"
            :hide-clear-selection="hideClearSelection"
            :read-only="readOnly"
            :search-input-id="searchInputId"
            :search-automation-id="searchAutomationId"
            :selected-rows="selectedRows"
            :default-title-margin="groupingEnabled"
            :has-actions="hasActions"
            v-model:search-value="searchText"
            v-model:include-inactive="includeInactiveValue"
            v-model:toolbar-active="toolbarActive"
            v-model:can-float="headerCanFloat"
            @clear-search="$emit(searchEvents.CLEAR_SEARCH)"
            @search="emitSearch"
            @default-action="handleDefaultAction"
            @action="emitActionEvent"
            @clear-selection="onClearSelection">

            <slot name="toolbar"></slot>

            <ul
                v-if="groupingEnabled"
                v-show="groupingOptions.visible"
                class="nav ms-2">
                <li :class="{ 'nav-item form-group': true, 'has-error': isGroupListInvalid }">
                    <dx-tag-box
                        :input-attr="groupingOptions.idAttrs"
                        :placeholder="groupingOptions.placeholder"
                        :search-enabled="groupingOptions.searchEnabled"
                        :show-clear-button="groupingOptions.showClearButton"
                        :max-displayed-tags="groupingOptions.maxDisplayedTags"
                        :show-drop-down-button="groupingOptions.showDropDownButton"
                        :multiline="groupingOptions.multiline"
                        :show-selection-controls="true"
                        :defer-rendering="false"
                        apply-value-mode="useButtons"
                        :class="{ 'form-control rq-grouping-tagbox': true, 'form-control-sm': groupingOptions.small }"
                        value-expr="dataField"
                        display-expr="caption"
                        :data-source="groupableColumns"
                        v-model:value="groupedColumns"
                    />
                    <rq-validation-feedback no-field-label small>
                        Results can only be grouped by up to {{groupingOptions.maxSelectedGroups}} columns at once.
                    </rq-validation-feedback>
                </li>
            </ul>
        </rq-grid-action-header>

        <rq-grid-filter-layout :mode="rqFilterMode">

            <template #panel>
                <rq-grid-filter-panel
                    v-if="dxReady"
                    ref="gridFilterPanel"
                    :grid-instance="gridInstance"
                    :target-inactive-column="targetInactiveColumn"
                    :filter-options="rqFilterOptions"
                    v-model:filter-state="gridFilterState"
                    @filter-change="onRqFilterChange"
                    @reset="clearFilters">
                    <template #header>
                        <slot name="filter-panel-header"></slot>
                    </template>
                </rq-grid-filter-panel>
            </template>

            <template #default>

                <!--Grid Action Bar-->
                <rq-grid-action-bar
                    :actions="actionList"
                    :selected-items="selectedRows"
                    :read-only="readOnly"
                    :check-action-permission="checkActionPermission"
                    @action-click="emitActionEvent"
                />

                <div :id="gridContainerId" :class="dxClassAttr">
                    <rq-grid-filter-popover
                        :automation_id="automation_id"
                        v-if="columnFiltersActive && dxReady"
                        ref="gridFilterPopover"
                        :target="filterTarget"
                        :container="gridContainerId"
                        :column="filterColumn"
                        :grid-instance="gridInstance"
                        v-model:visible="columnFilterVisible"
                        v-model:filter-state="gridFilterState"
                        :clear-before-next-apply="clearBeforeNextFilter"
                        @filter-change="onRqFilterChange"
                    />
                    <template v-if="columnFiltersActive && dxReady">
                        <dx-tooltip
                            v-for="tooltipInfo in disabledFilterTooltips"
                            :key="tooltipInfo.targetId"
                            :target="`#${tooltipInfo.targetId}`"
                            :position="'top'"
                            container="body"
                            show-event="dxhoverstart"
                            hide-event="dxhoverend">
                            <span v-html="tooltipInfo.text"></span>
                        </dx-tooltip>
                        <!-- <b-tooltip
                            v-for="tooltipInfo in disabledFilterTooltips"
                            :key="tooltipInfo.targetId"
                            :target="tooltipInfo.targetId"
                            placement="top"
                            triggers="hover"
                            container="body"
                            boundary="window">
                            <span v-html="tooltipInfo.text"></span>
                        </b-tooltip> -->
                    </template>
                    <div :id="gridId" ref="gridElement"></div>
                </div>

            </template>
        </rq-grid-filter-layout>
        <slot></slot>
        <!-- TG - Needs further research to find a viable solution that doesn't have adverse affects with $rqBusy -->
        <!-- <rq-loading-indicator :theme="currentTheme" :visible="gridBusy" /> -->
    </div>
</template>

<script>
    import { useRqDxDataGrid } from "../composables";
    import GridUtil from "../GridUtil";
    import {
        RqGridFilterOptions,
        RqColumnFilterOptions,
        RqGridGroupingOptions,
        RqGridColumnOptions
    } from "../models";
    import {
        FILTER_MODE,
        FOCUS_AFTER_MODE,
        DEFAULT_TOOLBAR_ACTIONS
    } from "../enums";
    import RqDxDataGrid from "../DataGrid.vue";
    import RqGridActionHeader from "./RqGridActionHeader.vue";
    import RqGridActionBar from "./RqGridActionBar.vue";
    import RqGridFilterLayout from "./RqGridFilterLayout.vue";
    import RqGridFilterPanel from "./RqGridFilterPanel.vue";
    import RqGridFilterPopover from "./RqGridFilterPopover.vue";
    import GridAction from "./GridAction.js";
    import { DxTooltip } from "devextreme-vue/tooltip";

    const truncateTextElement = (content, text=null) => $("<span/>")
        .addClass("text-truncate")
        .attr("title", text || content)
        .append(content);

    export default {
        name: "RqActionDataGrid",
        extends: RqDxDataGrid,
        components: {
            RqGridActionHeader,
            RqGridActionBar,
            RqGridFilterLayout,
            RqGridFilterPanel,
            RqGridFilterPopover,
            DxTooltip
        },
        props: {
            id: { type: String, default: () => _.uniqueId("rq-action-grid-") },
            title: { type: String, default: "" },
            titleSize: { type: String, default: "lg" },
            noDataText: { type: String, default: null },
            actions: { type: Array, default: () => [] },
            theme: { type: String, default: "" },
            searchValue: { type: String, default: "" },
            showIncludeInactive: { type: Boolean, default: false},
            targetInactiveColumn: { type: String, default: "isInactive" },
            targetReadonlyColumn: { type: String, default: null },
            hideSearch: { type: Boolean, default: false },
            hideToolbar: { type: Boolean, default: false },
            hideDefaultActions: { type: Boolean, default: false },
            hideSettings: { type: Boolean, default: false },
            hideShowColumnChooser: { type: Boolean, default: false },
            hideExport: { type: Boolean, default: false },
            hideReset: { type: Boolean, default: false },
            hideClearFilters: { type: Boolean, default: false },
            hideClearSelection: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false },
            checkActionPermission: { type: Function, default: () => () => true },
            exportFileName: { type: String, default: null },
            showNativeToolbar: { type: Boolean, default: false },
            searchMode: { type: String, default: "field" },
            integratedSearch: { type: Boolean, default: false },
            remoteSearch: { type: Boolean, default: false },
            forceSearchRefresh: { type: Boolean, default: false },
            validationErrors: { type: Array, default: () => [] },
            strikethroughIfTrue: { type: Array, default: () => [] },
            strikethroughIfFalse: { type: Array, default: () => [] },
            focusAfterInsert: { type: String, default: null },
            focusAfterLastRow: { type: String, default: null },
            rqFilters: { type: [Boolean, Object], default: false },
            rqEditable: { type: Boolean, default: false },
            rqGrouping: { type: [Boolean, Object], default: false },
            fixedHeader: { type: Boolean, default: false },
            forceFloatingHeader: { type: Boolean, default: false },
            persistFilters: { type: Boolean, default: true },
            minSearchTextLength: { type: Number, default: 3},
            singleSelectionEnabled: { type: Boolean, default: false },
            searchInputId: { type: String, default: () => _.uniqueId("txt_grid_search-") },
            searchAutomationId: { type: String, default: "txt_grid_search" },
            preventPersistColumns: { type: Array, default: () => [] },
            //overridden base props
            persistState: { type: Boolean, default: true }
        },
        provide() {
            return {
                gridAutomationId: this.automation_id
            };
        },
        setup() {
            return useRqDxDataGrid();
        },
        data() {
            return {
                actionList: [],
                toolbarActionList: [],
                toolbarSettingsList: [],
                selectedRows: [],
                searchEvents: {
                    SEARCH: "search",
                    SEARCH_KEY_UP: "search-key-up",
                    CLEAR_SEARCH: "clear-search"
                },
                searchText: "",
                filterTarget: "",
                filterColumn: null,
                columnFilterVisible: false,
                gridBusy: false,
                hasBrokenRules: false,
                gridFilterState: {},
                clearBeforeNextFilter: false,
                defaultFilterExpr: [],
                singleSelectionInProgress: false,
                disabledFilterTooltips: [],
                groupableColumns: [],
                groupedColumns: [],
                isGroupListInvalid: false,
                includeInactiveValue: false,
                toolbarActive: false,
                headerCanFloat: false
            };
        },
        computed: {
            hasTitle() { return !_.isEmpty(_.trim(this.title)); },
            hasActions() { return !_.isEmpty(this.actionList); },
            showActions() { return !_.isEmpty(this.selectedRows); },
            currentTheme() { return this.theme || _.get(this, "$route.meta.theme", "") || "default"; },
            floatHeader() { return this.forceFloatingHeader || (this.headerCanFloat && !this.fixedHeader); },
            classAttr() {
                return {
                    "grid-container": true,
                    [`theme-${this.currentTheme}`]: true,
                    "rq-grid-with-toolbar": this.toolbarActive,
                    "rq-grid-with-actions": this.hasActions,
                    "rq-grid-selection-navbar-active": this.hasActions && this.showActions,
                    "rq-grid-multi-select": this.multiSelectEnabled,
                    "rq-grid-header-floating": this.floatHeader,
                    "rq-grid-allow-select-all": this.allowSelectAll,
                    "rq-grid-rows-selected": this.hasSelectedRows,
                    "rq-grid-column-filter-visible": this.columnFilterVisible,
                    "rq-grid-panel-filters-active": this.panelFiltersActive
                };
            },
            dxClassAttr() {
                return {
                    "dx-grid-container": true,
                    [`theme-${this.currentTheme}`]: true,
                    "rq-filters-active": this.columnFiltersActive
                };
            },
            multiSelectEnabled() { return _.get(this, "dxConfig.selection.mode", null) === "multiple"; },
            allowSelectAll() { return _.getBool(this, "dxConfig.selection.allowSelectAll", true); },
            persistGridState() { return this.persistState && !this.hideToolbar && !this.hideDefaultActions && !this.hideSettings && !this.hideReset; },
            _validationErrors: {
                get() { return this.validationErrors; },
                set(val) { this.$emit("update:validationErrors", val); }
            },
            isValid() { return !this.hasBrokenRules && _.isEmpty(this.validationErrors); },
            focusAfterInsertMode() { return _.isEmpty(this.focusAfterInsert) ? FOCUS_AFTER_MODE.newRow : this.focusAfterInsert; },
            focusAfterLastRowMode() { return _.isEmpty(this.focusAfterLastRow) ? this.focusAfterInsertMode : this.focusAfterLastRow; },
            hasSelectedRows() { return !_.isEmpty(this.selectedRows); },
            groupingOptions() { return new RqGridGroupingOptions(this.rqGrouping); },
            groupingEnabled() { return this.groupingOptions.enabled && !_.isEmpty(this.groupableColumns); },
            hasTargetInactiveColumn() { return !_.isEmpty(this.targetInactiveColumn); },
            hasIntegratedSearch() { return this.integratedSearch && !_.isEmpty(this.searchText); },
            rqFilterOptions() { return new RqGridFilterOptions(this.rqFilters); },
            rqFilterMode() { return this.rqFilterOptions.mode; },
            rqFiltersEnabled() { return this.rqFilterOptions.mode !== FILTER_MODE.none; },
            columnFiltersActive() { return this.rqFilterMode === FILTER_MODE.default; },
            panelFiltersActive() { return this.rqFilterMode === FILTER_MODE.panel; }
        },
        watch:{
            actions:{
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    let mappedActions = _.map(newValue, a => new GridAction(a));
                    this.actionList = _.filter(mappedActions, a => !a.isToolbarAction && !a.isToolbarSetting);
                },
                immediate: true
            },

            exportFileName(newValue, oldValue) {
                if(newValue === oldValue || !this.gridInstance) return;
                this.gridInstance.option("export", { enabled: true, fileName:newValue });
            },

            searchValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.searchText) return;
                    this.searchText = newValue;
                },
                immediate: true
            },

            searchText(newValue, oldValue) {
                if(_.isEmpty(newValue) && this.integratedSearch) this.doIntegratedSearch(newValue);
                if(newValue === oldValue || newValue === this.searchValue) return;
                this.$emit("update:searchValue", newValue);
                if(this.integratedSearch && newValue.length >= this.minSearchTextLength)
                    this.doIntegratedSearch(newValue);
            },

            groupedColumns(newValue, oldValue) {
                if(!this.groupingEnabled || newValue === oldValue) return;
                if(newValue.length > this.groupingOptions.maxSelectedGroups) {
                    this.isGroupListInvalid = true;
                    return;
                }
                this.isGroupListInvalid = false;
                this.setGridGroupedColumns(newValue);
            },

            includeInactiveValue(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.setColumnOption(this.targetInactiveColumn, "visible", newValue);
                if (newValue) {
                    if(this.hasFilter(this.targetInactiveColumn)) {
                        this.updateFilter(this.targetInactiveColumn, null);
                        if(this.hasIntegratedSearch)
                            this.invokeGridMethod("searchByText", this.searchText);
                    }
                    else if(this.hasIntegratedSearch)
                        this.invokeGridMethod("searchByText", this.searchText);
                    else
                        this.invokeGridMethod("refresh");
                }
                else
                    this.updateFilter(this.targetInactiveColumn, newValue, false,  "=");
            }

        },
        methods: {
            setIdentifiers() {
                this.gridId = `${this.id}-dx-data-grid`;
                this.gridContainerId = `${this.id}-grid-container`;
            },

            setDefaultConfig() {
                const self = this;
                self.defaultConfig = {
                    columns: [],
                    dataService: null,
                    editing: { mode: "form", texts: { confirmDeleteMessage: "" } },
                    wrapperAttr: { id: self.gridId, automation_id: self.automation_id },
                    headerFilter: { visible: false },
                    loadPanel: {
                        enabled: true,
                        showIndicator: false,
                        showPane: false,
                        text: "",
                        onShowing() {
                            self.gridBusy = true;
                        },
                        onHidden() {
                            self.gridBusy = false;
                        }
                    },
                    pager: {
                        showPageSizeSelector: true,
                        allowedPageSizes: [50, 100, 500],
                        showNavigationButtons: true,
                        showInfo: true
                    },
                    paging: { pageSize: 50 },
                    dataRowTemplate: null,
                    searchPanel: { visible: false },
                    sorting: { mode: "single" },
                    width: "100%",
                    height: "100%",
                    showBorders: true,
                    showColumnLines: true,
                    rowAlternationEnabled: true,
                    wordWrapEnabled: true,
                    columnAutoWidth: true,
                    allowColumnResizing: true,
                    allowColumnReordering: true,
                    columnResizingMode: "nextColumn",
                    columnFixing: { enabled: true },
                    columnChooser: { enabled: true, mode: "select" },
                    hoverStateEnabled: true,
                    focusedRowEnabled: true,
                    selection: { mode: "multiple", allowSelectAll: true, selectAllMode: "page" },
                    scrolling: { showScrollbar: "onHover", useNative: false }
                };

                if(!self.hideExport && !self.hideToolbar) {
                    let defaultName = _.get(self, "$route.meta.label", null) || "HorizonDataExport";
                    let fileName = self.exportFileName
                        ? self.exportFileName
                        : _.snakeCase(defaultName);
                    self.defaultConfig.export = { enabled: false, fileName };
                }

                if(!_.isNil(self.noDataText))
                    self.defaultConfig.noDataText = self.noDataText;

                if(self.groupingOptions.enabled)
                    self.defaultConfig.grouping = { contextMenuEnabled: self.groupingOptions.contextMenuEnabled };
            },

            //called from "updateConfig" in base component; overrides base method
            setStateStoring(cfg=null) {
                const self = this;
                let storageKey = self.getStorageKey(cfg);

                if(_.isNil(storageKey)) return;

                const excludedProperties = [
                    "searchText",
                    "selectedRowKeys",
                    "focusedRowKey",
                    "focusedRowIndex",
                    "rqFilterState"
                ];
                self.dxConfig.stateStoring = {
                    enabled: true,
                    type: "custom",
                    storageKey,
                    customLoad() {
                        let state = localStorage.getItem(storageKey);

                        if(!state) return;

                        let stateObj = JSON.parse(state);

                        if(self.persistFilters && self.rqFiltersEnabled) {
                            let filterState = _.get(stateObj, "rqFilterState", null) || {};
                            let filterValue = _.get(stateObj, "filterValue", null) || [];
                            if(_.isEmpty(filterValue))  {
                                self.gridFilterState = filterState;
                                stateObj.filterValue = _.isEmpty(filterState)
                                    ? null
                                    : GridUtil.getCombinedFilterExpression(filterState);
                            }
                            else {
                                self.gridFilterState = GridUtil.getStateFromExpr(filterValue, filterState);
                            }
                            if(self.showIncludeInactive && self.hasTargetInactiveColumn) {
                                if(_.isNil(self.gridFilterState)) self.gridFilterState = {};
                                self.gridFilterState[self.targetInactiveColumn] = {
                                    value: false,
                                    filters: [self.targetInactiveColumn, "=", false]
                                };
                                stateObj.filterValue = GridUtil.getCombinedFilterExpression(self.gridFilterState);
                            }
                            self.clearBeforeNextFilter = !_.isEmpty(self.gridFilterState);
                        }

                        //prevent persist columns
                        let excludeCols = self.preventPersistColumns.slice();
                        if(self.showIncludeInactive && !_.includes(excludeCols, self.targetInactiveColumn)) {
                            excludeCols.push(self.targetInactiveColumn);
                        }
                        if(!_.isEmpty(excludeCols)) {
                            let stateCols = [];
                            _.forEach(stateObj.columns, col => {
                                if(!_.includes(excludeCols, col.dataField)) {
                                    stateCols.push(col);
                                    return;
                                }
                                let configCol = _.find(self.dxConfig.columns, { dataField: col.dataField });
                                stateCols.push(_.cloneDeep(configCol));
                            });
                            stateObj.columns = stateCols;
                        }

                        return _.omit(stateObj, excludedProperties);
                    },
                    customSave(state) {
                        self.saveGridState(storageKey, state);
                    }
                };
            },

            saveGridState(key, state, filters=null) {
                let stateObj = _.cloneDeep(state);
                stateObj.rqFilterState = {};
                if(!_.isEmpty(this.gridFilterState) && this.persistFilters) {
                    let filterValue = _.isEmpty(filters)
                        ? GridUtil.getCombinedFilterExpression(this.gridFilterState)
                        : filters;
                    stateObj.rqFilterState = _.cloneDeep(this.gridFilterState);
                    stateObj.filterValue = _.isArray(filterValue) ? filterValue.slice() : [filterValue];
                }
                var jsonState = JSON.stringify(stateObj);
                this.$events.emit("rq-grid:save-grid-state", { key, state: jsonState });
                localStorage.setItem(key, jsonState);
            },

            handleSingleSelection(e) {
                if(!this.singleSelectionEnabled || this.singleSelectionInProgress) return;

                this.singleSelectionInProgress = true;
                e.component.selectRows(e.currentSelectedRowKeys[0], false);
                this.singleSelectionInProgress = false;
            },

            onConfigUpdateComplete(cfg=null) {
                const self = this;

                if(self.showIncludeInactive && !_.isEmpty(self.targetInactiveColumn)) {
                    let inactiveColumn = _.find(self.dxConfig.columns, { dataField: self.targetInactiveColumn });
                    inactiveColumn.visible = false;
                    let inactiveFilter = [self.targetInactiveColumn, "=", false];
                    if(_.isEmpty(self.dxConfig.filterValue)) {
                        self.dxConfig.filterValue = inactiveFilter;
                    }
                    else {
                        self.dxConfig.filterValue.push("and");
                        self.dxConfig.filterValue.push(inactiveFilter);
                    }
                }

                if(self.rqFiltersEnabled && !_.isEmpty(self.dxConfig.filterValue)) {
                    self.defaultFilterExpr = _.cloneDeep(self.dxConfig.filterValue);
                    self.gridFilterState = GridUtil.getStateFromExpr(self.defaultFilterExpr);
                }

                if(self.groupingOptions.enabled) {
                    self.refreshGroupableColumns(self.dxConfig.columns);
                }
                self.dxConfig.onOptionChanged = function(e) { self.onOptionChanged(e); };

                _.forEach(self.dxConfig.columns, c => {
                    //nothing to do if no options are passed
                    if(_.isNil(c.rqOptions)) return;

                    let rqColOpts = new RqGridColumnOptions(c.rqOptions);

                    //don't override existing cellTemplate
                    if(!_.isNil(c.cellTemplate) && (rqColOpts.highlightSearchText || rqColOpts.truncateCellText)) {
                        console.warn(`RqDxActionDataGrid - column.dataField: ${c.dataField} :: highlightText, highlightSearchText, and truncateCellText rqOptions cannot function with an existing "cellTemplate" DevEx column option.`);
                        return;
                    }

                    if(self.remoteSearch && rqColOpts.highlightSearchText) {
                        c.cellTemplate = (cellElement, cellInfo) => self.highlightCellText(cellElement, cellInfo, self.searchText, rqColOpts.truncateCellText);
                    }
                    else if(_.isFunction(rqColOpts.highlightText)) {
                        c.cellTemplate = (cellElement, cellInfo) => {
                            let textToHighlight = rqColOpts.highlightText(cellInfo);
                            self.highlightCellText(cellElement, cellInfo, textToHighlight, rqColOpts.truncateCellText);
                        }
                    }
                    else if(rqColOpts.truncateCellText) {
                        c.cellTemplate = (cellElement, cellInfo) => {
                            let text = cellInfo.displayValue || cellInfo.text || "";
                            truncateTextElement(text).appendTo(cellElement);
                        };
                    }
                });

                if(self.panelFiltersActive) {
                    self.dxConfig.onInitialized
                }

                if(!self.rqEditable) return;

                let showCheckBoxesMode = _.get(self.dxConfig, "selection.showCheckBoxesMode", "always");
                _.set(self.dxConfig, "selection.showCheckBoxesMode", showCheckBoxesMode);

                self.dxConfig.editing = {
                    mode: "row",
                    allowUpdating: true,
                    allowAdding: true,
                    selectTextOnEditStart: true
                };

                let hasButtonsColumn = _.some(self.dxConfig.columns, c => c.type === "buttons");
                //if the consumer has specified a button column, take it as is, otherwise add one for good measure
                if(!hasButtonsColumn) {
                    self.dxConfig.columns.push({ name: "dx-buttons", type: "buttons", visible: false, showInColumnChooser: false });
                }

                _.forEach(self.dxConfig.columns, c => {
                    if(_.parseBool(c.allowEditing, true)) return;
                    c.cssClass = _.joinParts([c.cssClass, "rq-readonly-cell"], " ");
                });

                self.dxConfig.onRowInserted = function(e) { self.onRowInserted(e); };
            },

            onContentReady(e) {
                const self = this;
                self.$rqBusy.endWait();
                self.dxReady = true;
                if(!self.gridInstance.hasEditData()) {
                    if(!_.isEmpty(self._validationErrors)) {
                        self._validationErrors = [];
                    }
                    self.hasBrokenRules = false;
                }
                if(self.columnFiltersActive) {
                    self.refreshDisabledFilterTooltips();
                }
                if(self.panelFiltersActive) {
                    self.invokeFilterMethod("refreshFilterVisibility");
                }
                if(self.groupingOptions.enabled) {
                    self.refreshGroupedColumnsValue();
                }
                if(self.hasSelectedRows) {
                    self.$nextTick(() => {
                        self.selectedRows = self.gridInstance.getSelectedRowsData();
                    });
                }
                self.bubbleDxEvent("contentReady", "onContentReady", e);
            },

            onOptionChanged(e) {
                const self = this;
                if(self.groupingOptions.enabled && _.includes(e.fullName, "allowGrouping")) {
                    self.refreshGroupableColumns();
                }
                self.bubbleDxEvent("optionChanged", "onOptionChanged", e);
            },

            onRqFilterChange(e) {
                if(!this.gridInstance) return;
                let storageKey = this.getStorageKey();
                this.$nextTick(() => {
                    this.$emit("filter-change", e);
                });
                if(_.isNil(storageKey)) return;
                this.saveGridState(storageKey, this.gridInstance.state(), e.filterExpr);
            },

            onSelectionChanged(e) {
                const self = this;
                self.selectedRows = e.selectedRowsData.slice();
                if(self.rqEditable) {
                    self.selectedRowKeys = e.selectedRowKeys;
                    if(!_.isEmpty(self.selectedRowKeys)) {
                        self.resolveEditRow();
                    }
                }
                else if(!_.isEmpty(self.selectedRowKeys) && _.isEmpty(e.selectedRowKeys)) {
                    self.selectedRowKeys = [];
                }
                self.bubbleDxEvent("selectionChanged", "onSelectionChanged", e);
                self.handleSingleSelection(e);
            },

            onKeyDown(e) {
                const self = this;
                if(self.rqEditable && e.event.key === "Escape") {
                    self.$nextTick().then(() => {
                        if(e.component.hasEditData()) return;
                        self._validationErrors = [];
                        self.hasBrokenRules = false;
                    });
                }
                if(e.event.key === "Enter"){
                    self.$emit("enterKeyDown", e);
                }
                self.bubbleDxEvent("keyDown", "onKeyDown", e);
            },

            onCellClick(e) {
                const self = this;
                // let rowReadOnly = self.rqEditable
                //     ? !_.isEmpty(self.targetReadonlyColumn) && _.getBool(e, `row.data.${self.targetReadonlyColumn}`)
                //     : true;
                if(self.rqEditable && e.rowType === "data" && e.column.type !== "selection" && !e.row.isEditing) {
                    let rowIndex = e.rowIndex;
                    e.component.clearSelection();
                    self.$nextTick(() => { self.setEditRow(rowIndex); });
                }
                self.bubbleDxEvent("cellClick", "onCellClick", e);
            },

            onRowClick(e) {
                const self = this;
                if(!self.rqEditable) {
                    if(self.selectedRowKeys.length === 1 && self.selectedRowKeys[0] === e.key) {
                        e.component.deselectRows([e.key]);
                    }
                    self.selectedRowKeys = e.component.getSelectedRowKeys();
                }
                self.bubbleDxEvent("rowClick", "onRowClick", e);
            },

            onEditorPreparing(e) {
                const self = this;
                if(self.rqEditable) {
                    let editableColumns = _.filter(e.component.getVisibleColumns(), c => c.type !== "selection" && _.parseBool(c.allowEditing));
                    let firstDataField = _.first(editableColumns).dataField;
                    let lastDataField = _.last(editableColumns).dataField;
                    let isOneCol = firstDataField === lastDataField;
                    if (isOneCol || e.dataField === firstDataField || e.dataField === lastDataField) {
                        if(e.editorName === "dxSelectBox" || e.editorName === "dxTagBox") {
                            e.editorOptions.customItemCreateEvent = "input";
                        }
                        else {
                            e.editorOptions.valueChangeEvent = "input";
                        }
                        e.editorElement.on("keydown", function(keydownEvent) {
                            let shiftKey = _.parseBool(keydownEvent.shiftKey);
                            if(keydownEvent.key === "Tab" && (isOneCol
                                || (e.dataField === firstDataField && shiftKey)
                                || (e.dataField === lastDataField && !shiftKey))) {
                                keydownEvent.preventDefault();
                                keydownEvent.stopImmediatePropagation();
                                self.setNextRow(e.row, shiftKey);
                            }
                        });
                    }
                    let rowReadOnly = !_.isEmpty(self.targetReadonlyColumn) && _.getBool(e, `row.data.${self.targetReadonlyColumn}`);
                    if(e.parentType === "dataRow" && e.type !== "selection" && rowReadOnly) {
                        e.editorOptions.disabled = true;
                        if(e.dataType === "boolean") {
                            $(e.editorElement.parent()).addClass("rq-disabled-cell");
                        }
                    }
                }
                self.bubbleDxEvent("editorPreparing", "onEditorPreparing", e);
            },

            onRowValidating(e) {
                const self = this;
                let brokenRules = _.get(e, "brokenRules", null) || [];
                self.hasBrokenRules = brokenRules.length > 0;
                self._validationErrors = _.map(brokenRules, r => r.message);
                self.bubbleDxEvent("rowValidating", "onRowValidating", e);
            },

            onRowPrepared (e) {
                const self = this;
                if(self.strikethroughRow(e)) {
                    e.rowElement.addClass("rq-strike-through");
                }
                self.bubbleDxEvent("rowPrepared", "onRowPrepared", e);
            },

            onRowInserted(e) {
                const self = this;
                self.bubbleDxEvent("rowInserted", "onRowInserted", e);
            },

            onCellPrepared(e) {
                const self = this;
                // Header title alignment
                // if(e.rowType === "header" && !e.cellElement.hasClass("dx-editor-cell")) {
                //     e.cellElement.removeAttr("style");
                //     let $content = e.cellElement.find(".dx-datagrid-text-content");
                //     if($content.length > 0){
                //         $content
                //             .removeClass("dx-text-content-alignment-right")
                //             .addClass("dx-text-content-alignment-left");
                //     }
                //     let $indicators = e.cellElement.find(".dx-column-indicators");
                //     if($indicators.length > 0) {
                //         $indicators.removeAttr("style");
                //         if($indicators.length > 1 && $indicators.hasClass("dx-visibility-hidden")) {
                //             e.cellElement.remove(".dx-column-indicators.dx-visibility-hidden");
                //         }
                //     }
                // }

                if(e.rowType === "header" && !e.cellElement.hasClass("dx-editor-cell")) {
                    // let visibleDisabledFilterCount = self.hasDisabledFilters && _.sumBy()
                    let $indicators = e.cellElement.find(".dx-column-indicators");
                    if($indicators.length > 0 && self.columnFiltersActive && e.column.allowFiltering) {
                        self.removeDisabledFilterTooltip(e.column.dataField);
                        let filterId = `rq-filter-${e.column.headerId}`;
                        let $filterIcon = $("<span />")
                            .attr("id", filterId)
                            .attr("automation_id", `fil_${self.automation_id}_${e.column.name}`)
                            .addClass("rq-filter-icon")
                            .append(`<svg><use href="#rq-fas-filter"></use></svg>`);

                        let filterOpts = new RqColumnFilterOptions(e.column.rqFilter);
                        if(filterOpts.isDisabled(_.cloneDeep(e.column.rqFilter))) {
                            $filterIcon.addClass("rq-filter-disabled");
                            self.addDisabledFilterTooltip(filterId, e.column.dataField, filterOpts.disabledTooltip);
                        }
                        else{
                            $filterIcon.on("click", function(clickEvent) {
                                self.handleFilterClick(e, clickEvent);
                            });
                        }

                        if(_.hasIn(this.gridFilterState, e.column.dataField)){
                            $filterIcon.addClass("rq-filter-active");
                        }
                        $indicators.append($filterIcon);
                    }
                }
                self.bubbleDxEvent("cellPrepared", "onCellPrepared", e);
            },

            onClearSelection() {
                this.invokeGridMethod("option", "focusedRowIndex", -1);
                this.invokeGridMethod("clearSelection");
            },

            addDisabledFilterTooltip(targetId, dataField, tooltipText) {
                let tooltipInfo = { targetId, dataField };
                if(_.isEmpty(tooltipText)) {
                    console.warn("RqFilter for column, \"%s\", was not proivided valid disabled tooltip text.  Please provide a value for \"rqFilter.disabledTooltip\".", dataField);
                    tooltipInfo.text = `${_.startCase(dataField)} filter is disabled.`;
                }
                else {
                    tooltipInfo.text = tooltipText;
                }
                this.disabledFilterTooltips.push(tooltipInfo);
            },

            removeDisabledFilterTooltip(dataField, index = -1) {
                let itemIndex = _.findIndex(this.disabledFilterTooltips, { dataField });
                if(itemIndex < 0) return;
                this.disabledFilterTooltips.splice(itemIndex, 1);
            },

            //removes any disabled filter tooltips for columns not visible
            refreshDisabledFilterTooltips() {
                if(!this.columnFiltersActive || !this.dxReady) return;
                let visibleCols = this.invokeGridMethod("getVisibleColumns");
                let visibleColFields = _.map(visibleCols, c => c.dataField);
                let newTooltips = [];
                _.forEach(this.disabledFilterTooltips, t => {
                    if(!_.includes(visibleColFields, t.dataField)) return;
                    newTooltips.push(t);
                });
                this.disabledFilterTooltips = newTooltips;
            },

            // TG - Needs more discussion as to what does/doesn't trigger committing or cancelling an add/edit row
            // onAnyDocumentClick(e) {
            //     this.$nextTick(() => {
            //         let gridElement = _.get(this, "$refs.gridElement", null);
            //         if(_.invoke(e, "path.includes", gridElement) || _.invoke(e, "target.classList.contains", "dx-item-content")) return;
            //         this.resolveEditRow();
            //     });
            // },

            isLastRow(rowOrRowIndex) {
                let visibleRows = self.invokeGridMethod("getVisibleRows");
                let lastRow = _.last(visibleRows);
                return lastRow.rowIndex === _.isNumber(rowOrRowIndex)
                    ? rowOrRowIndex
                    : rowOrRowIndex.rowIndex;
            },

            setGridFocus(rowIndex, visibleColIndex=null) {
                let colIndex = _.parseNumber(visibleColIndex, 0);
                let focusCell = this.invokeGridMethod("getCellElement", rowIndex, colIndex);
                if(colIndex === 0 && focusCell.hasClass("dx-command-select"))
                    focusCell = this.invokeGridMethod("getCellElement", rowIndex, 1);
                this.invokeGridMethod("focus", focusCell);
            },

            setNextRow(row, shiftKey) {
                const self = this;
                let visibleRows = self.invokeGridMethod("getVisibleRows");
                let lastRow = _.last(visibleRows);
                let isNewRow = _.parseBool(row.isNewRow);
                let isLastRow = lastRow.rowIndex === row.rowIndex;
                let focusNewAfterInsert = self.focusAfterInsertMode === FOCUS_AFTER_MODE.newRow;
                let focusNewAfterLast = self.focusAfterLastRowMode === FOCUS_AFTER_MODE.newRow;

                if((focusNewAfterInsert && isNewRow) || (focusNewAfterLast && isLastRow)) {
                    self.setAddRow();
                } else {
                    let nextIndex = row.rowIndex + (shiftKey ? -1 : 1);
                    if(nextIndex < 0) {
                        nextIndex = lastRow.rowIndex;
                    }
                    else if(nextIndex > lastRow.rowIndex) {
                        nextIndex = 0;
                    }
                    self.setEditRow(nextIndex);
                }
            },

            async saveRow() {
                const self = this;
                if(!self.invokeGridMethod("hasEditData")) return true;
                await self.invokeGridMethod("saveEditData");
                return self.isValid;
            },

            async setAddRow() {
                const self = this;
                let isValid = await self.saveRow();
                if(!isValid) return false;
                self.invokeGridMethod("addRow");
                self.$nextTick(() => { self.setGridFocus(0, 0); });
                return true;
            },

            async setEditRow(rowIndex) {
                const self = this;
                let isValid = await self.saveRow();
                if(!isValid) return false;
                self.invokeGridMethod("editRow", rowIndex);
                return true;
            },

            strikethroughRow(e) {
                if (e.rowType !== "data" || (_.isEmpty(this.strikethroughIfTrue) && _.isEmpty(this.strikethroughIfFalse)))
                    return false;
                return _.some(this.strikethroughIfTrue, field => _.parseBool(e.data[field]))
                    || _.some(this.strikethroughIfFalse, field => !_.parseBool(e.data[field]));
            },

            resolveEditRow() {
                if(!this.rqEditable || !this.isEditing()) return;
                if(this.invokeGridMethod("hasEditData"))
                    this.invokeGridMethod("saveEditData");
                else
                    this.invokeGridMethod("cancelEditData");
            },

            isEditing() {
                // DevEx recently added the "editRowKey" value, so now we actually have a built-in mechanism
                // to know if a row is currently in it's edit state.
                let editRowKey = _.parseNumber(this.invokeGridMethod("option", "editing.editRowKey"), -1);
                return editRowKey >= 0;
            },

            emitClearSearch() {
                this.searchText = "";
                this.$emit(this.searchEvents.CLEAR_SEARCH);
                this.searchPopoverVisible = false;
            },

            emitSearch(){
                if(this.integratedSearch)
                    this.doIntegratedSearch(this.searchText);
                this.$emit(this.searchEvents.SEARCH);
            },

            emitActionEvent(action) {
                let payload = action.createEventPayload(this.selectedRows);

                if(_.isFunction(action.onClick)) {
                    action.onClick(payload);
                    return;
                }

                if(action.eventName) {
                    this.$emit(action.eventName, payload);
                    return;
                }

                this.$emit("action", payload);
            },

            handleDefaultAction(action) {
                switch(action.name) {
                    case DEFAULT_TOOLBAR_ACTIONS.EXPORT:
                        this.invokeGridMethod("exportToExcel");
                        this.$emit("export");
                        break;
                    case DEFAULT_TOOLBAR_ACTIONS.COLUMN_CHOOSER:
                        this.invokeGridMethod("showColumnChooser");
                        this.$emit("show-column-chooser");
                        break;
                    case DEFAULT_TOOLBAR_ACTIONS.CLEAR_FILTERS:
                        this.clearFilters();
                        this.$emit("clear-filters");
                        this.$toast.success("Grid filters cleared.");
                        break;
                    case DEFAULT_TOOLBAR_ACTIONS.RESET:
                        this.reset();
                        this.$emit("reset");
                        this.$toast.success("Grid settings reset to default.");
                        break;
                }
            },

            handleFilterClick(cellPreparedOptions, clickEvent) {
                const self = this;
                let column = cellPreparedOptions.column;
                if((self.filterTarget !== column.headerId || !self.columnFilterVisible) && self.columnFiltersActive){
                    self.columnFilterVisible = false;
                    self.$nextTick().then(() => {
                        self.filterTarget = column.headerId;
                        self.filterColumn = column;
                        self.columnFilterVisible = true;
                    });
                }
                else {
                    self.columnFilterVisible = false;
                }

                clickEvent.stopPropagation();
                clickEvent.preventDefault();
            },

            clearFilters() {
                const self = this;
                self.invokeGridMethod("clearFilter");
                if(self.showIncludeInactive && !self.includeInactiveValue) {
                    self.$nextTick(() => {
                        self.invokeFilterMethod("reset", self.defaultFilterExpr);
                        self.invokeGridMethod("filter", self.defaultFilterExpr);
                    });
                }
                else
                    self.invokeFilterMethod("reset");
            },

            reset() {
                const self = this;
                self.invokeGridMethod("state", null);
                self.$nextTick(() => {
                    self.includeInactiveValue = false;
                    self.invokeFilterMethod("reset", self.defaultFilterExpr);
                });
            },

            resetFilter() {
                this.invokeFilterMethod("reset");
            },

            async removeColumnFilter(dataField, applyUpdatedFilters=true) {
                this.invokeFilterMethod("removeColumnFilter", dataField, applyUpdatedFilters);
                await this.$nextTick();
                return this.gridFilterState;
            },

            async removeColumnFilters(dataFields, applyUpdatedFilters=true) {
                this.invokeFilterMethod("removeColumnFilters", dataFields, applyUpdatedFilters);
                await this.$nextTick();
                return this.gridFilterState;
            },

            async updateFilter(dataField, values, isRange=false, operator=null) {
                this.invokeFilterMethod("updateFilter", dataField, values, isRange, operator);
                await this.$nextTick();
                return this.gridFilterState;
            },

            async updateFilters(filterList) {
                this.invokeFilterMethod("updateFilters", filterList);
                await this.$nextTick();
                return this.gridFilterState;
            },

            refreshGroupableColumns(cols=null) {
                const self = this;
                let visibleCols = _.isNil(cols) ? self.invokeGridMethod("getVisibleColumns") : cols;
                let result = [];
                _.forEach(visibleCols, col => {
                    if(!_.parseBool(col.allowGrouping, true) || col.type === "selection" || _.isNil(col.dataField)) return;
                    result.push({
                        dataField: col.dataField,
                        caption: col.caption || _.startCase(col.dataField)
                    });
                });
                if(_.isEqual(self.groupableColumns, result)) return;
                self.groupableColumns = _.sortBy(result, "caption");
            },

            refreshGroupedColumnsValue() {
                const self = this;
                let groupedColData = [];
                _.forEach(self.groupableColumns, col => {
                    let groupIndex = _.parseNumber(self.getColumnOption(col.dataField, "groupIndex"), -1);
                    if(groupIndex < 0) return;
                    groupedColData.push({
                        groupIndex,
                        dataField: col.dataField
                    });
                });
                let result = _.map(_.sortBy(groupedColData, "groupIndex"), "dataField");
                if(_.isEqual(self.groupedColumns, result)) return;
                self.groupedColumns = result;
            },

            setGridGroupedColumns(cols) {
                const self = this;
                if(_.isEmpty(cols)) {
                    self.invokeGridMethod("clearGrouping");
                    return;
                }
                let columns = _.filter(self.invokeGridMethod("option", "columns"), c => _.parseBool(c.allowGrouping, true) && c.type !== "selection");
                _.forEach(columns, (col,index) => {
                    let currentIndex = _.parseNumber(self.getColumnOption(col.dataField, "groupIndex"), -1);
                    let newIndex = _.indexOf(cols, col.dataField);
                    if(currentIndex === newIndex) return;
                    self.setColumnOption(col.dataField, "groupIndex", newIndex);
                });
            },

            getColumn(col) {
                return this.invokeGridMethod("columnOption", col);
            },

            getColumnOption(col, opt) {
                return this.invokeGridMethod("columnOption", col, opt);
            },

            setColumnOption(col, opt, value) {
                this.invokeGridMethod("columnOption", col, opt, value);
            },

            invokeGridMethod(method, ...params) {
                return _.invoke(this, `gridInstance.${method}`, ...params);
            },

            invokeFilterMethod(method, ...params) {
                let componentRef = this.panelFiltersActive
                    ? "gridFilterPanel"
                    : "gridFilterPopover";
                _.invoke(this, `$refs.${componentRef}.${method}`, ...params);
            },

            hasFilter(dataField=null) {
                return _.isNil(dataField)
                    ? !_.isEmpty(this.gridFilterState)
                    : _.has(this.gridFilterState, dataField);
            },

            doIntegratedSearch: _.debounce(function(searchTextValue) {
                if(!this.remoteSearch)
                    this.gridInstance.searchByText(searchTextValue);
                if(!this.forceSearchRefresh && !this.remoteSearch) return;
                this.gridInstance.refresh();
            }, 300),

            highlightCellText(cellElement, cellInfo, textToHighlight, truncate=false) {
                let cellContent = _.isEmpty(textToHighlight)
                    ? cellInfo.displayValue
                    : _.replace(cellInfo.displayValue,
                        new RegExp(textToHighlight, "ig"),
                        "<span class=\"dx-datagrid-search-text\">$&</span>");
                if(!truncate) {
                    cellElement.append(cellContent);
                    return;
                }
                truncateTextElement(cellContent, cellInfo.displayValue)
                    .appendTo(cellElement);
            }
        }
    };

</script>
